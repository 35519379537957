import "./assets/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FormPage from "./pages/FormPage";
import RequestReceivedPage from "./pages/RequestReceivedPage";
import React, { useState } from "react";
import Status from "./pages/Status";

function App() {
  const [orderSucess, setOrderSuccess] = useState("");

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<FormPage setOrderSuccess={setOrderSuccess} />}
        />
        <Route path="/status" element={<Status />} />
        <Route
          path="/success"
          element={<RequestReceivedPage orderSucess={orderSucess} />}
        />
        <Route
          path="*"
          element={<FormPage setOrderSuccess={setOrderSuccess} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
