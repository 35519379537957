import "./phoneDetail.scss";
import { Container } from "react-bootstrap";

import React from "react";

function PhoneDetail({ deviceData }) {
  return (
    <div className="phone__detail__">
      <Container>
        {/* <h2 className="head__">ضمان ( ايفون 13 برو ) </h2>
                <p className="parg__">
                    برنامج مجاني مقدم من ( هنا اسم المنتج )  الى جميع العملاء الشروط والأحكام
                </p>
                <ul className="detail_list__ list-unstyled">
                    <li> يعد هذا الضمان معتمد لبكج حماية  ( هنا اسم المنتج )  وهو ساري لمدة عام على الشاشة الأمامية من تاريخ الشراء.</li>
                    <li>  الضمان يشمل كسر الشاشة الأمامية.</li>
                    <li>  الضمان لا يشمل التلف أو الكسر الناتج عن سو الأستخدام.</li>
                    <li> يتم الحصول على الضمان من الموقع الرسمي (ابل )  ومراكز البيع المعتمدة لمنتجات (ايفون 13 برو )</li>
                    <li>يتم رفع صورة فاتورة الشراء والمعلومات الخاصة بالجهاز في صفحة الضمان خلال مده اقصاها 5 ايام من تاريخ شراء المنتج.</li>
                    <li>وجود المنتج على الجهاز للأستفاده من الضمان أو التعويض شرط أساسي.</li>
                    <li>يتم التعويض من خلال الموقع الالكتروني الخاص بالضمان ( ابل ستور )</li>
                    <li>يتم التعويض في مده أقصاها 15 يوم عمل عند توفر الشروط المطلوبة وبعد اجتياز الكشف الأولي.</li>
                    <li>عند استيفاء كامل الشروط والأحكام وتعذر تقديم خدمة الصيانة يتم التعويض بمبلغ (1000 ريال ) كحد أقصي.</li>
                    <li> تسجيل بياناتك في الصفحة الخاصة بالضمان يعني موافقتك على الشروط والأحكام الخاصة بضمان (ايفون 13 برو)</li>
                </ul> */}
        <div
          className="phone_detail_wrapper__"
          dangerouslySetInnerHTML={{ __html: deviceData }}
        ></div>
      </Container>
    </div>
  );
}

export default PhoneDetail;
