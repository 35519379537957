import axios from "axios";
import "./uploadFile.scss"
import { toast } from 'react-toastify';

function UploadFile({ title, uploadedFile, setUploadedFile }) {


    const handleFileUpload = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            return toast.error('لم يتم اختيار ملف', {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        };

        try {
            const { data } = await axios.post(
                "https://xwguarantee.ejjadh.com/api/upload",
                { file },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            toast.success('تم رفع الملف بنجاح', {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setUploadedFile(data.data);
            e.target.value = ''
        } catch (error) {
            setUploadedFile("")
            toast.error('يوجد مشكلة في رفع الملفات', {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            e.target.value = ''
        }
    }

    return (
        <div className="image_upload_block__ input_group__">
            <span className="label">{title}</span>
            <div className="input_wrapper__file__">
                <input
                    type="file"
                    className="input_file__"
                    onChange={handleFileUpload}
                />
                <svg
                    width="29"
                    height="30"
                    viewBox="0 0 29 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M25.6778 12.5H16.3404V2.5C16.3404 1.83696 16.0945 1.20107 15.6567 0.732234C15.2189 0.263393 14.6252 0 14.0061 0C13.387 0 12.7932 0.263393 12.3554 0.732234C11.9177 1.20107 11.6717 1.83696 11.6717 2.5V12.5H2.33435C1.71524 12.5 1.12149 12.7634 0.683715 13.2322C0.24594 13.7011 0 14.337 0 15C0 15.663 0.24594 16.2989 0.683715 16.7678C1.12149 17.2366 1.71524 17.5 2.33435 17.5H11.6717V27.5C11.6717 28.163 11.9177 28.7989 12.3554 29.2678C12.7932 29.7366 13.387 30 14.0061 30C14.6252 30 15.2189 29.7366 15.6567 29.2678C16.0945 28.7989 16.3404 28.163 16.3404 27.5V17.5H25.6778C26.2969 17.5 26.8907 17.2366 27.3284 16.7678C27.7662 16.2989 28.0122 15.663 28.0122 15C28.0122 14.337 27.7662 13.7011 27.3284 13.2322C26.8907 12.7634 26.2969 12.5 25.6778 12.5Z"
                        fill="#68767D"
                    />
                </svg>
            </div>

            {uploadedFile && uploadedFile.originalname && (
                <span className="file_uploaded_name__">{uploadedFile?.originalname}</span>
            )}
        </div>
    );
}

export default UploadFile;
