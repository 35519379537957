import React from "react";
import "./breadcrumps.scss";

export default function BreadCrumps({ status }) {
  return (
    <div class="breatcramps__">
      <div class="container">
        <ul class="breatcramps_list__ list-unstyled">
          <li class="li__">
            <a class="link__ transition__ home__" href="/">
              الرئيسية
            </a>
          </li>
          <li class="li__">
            <span class="link__ transition__ active">
              {status ? "حالة الطلب" : "تقديم طلب"}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}
