import React from "react";

import RequestReceived from "../components/RequestReceived";
const RequestReceivedPage = ({ orderSucess }) => {
  return (
    <>
      <RequestReceived orderSucess={orderSucess} />
    </>
  );
};

export default RequestReceivedPage;
