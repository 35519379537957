import React, { useEffect } from "react";
import img from "../assets/images/request_received.png";
import "./requestReceived.scss";
import { useNavigate } from "react-router-dom";

const RequestReceived = ({ orderSucess }) => {
  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);

  return (
    <div className="Request_received__">
      <img
        className="img_block__"
        src={img}
        alt="تم تلقى الطلب"
        width="318"
        height="318"
      />
      <h2 className="head__"> تم تلقي الطلب</h2>
      <p className="parg__">{`رقم الطلب الخاص بك هو  ${orderSucess}`}</p>
    </div>
  );
};

export default RequestReceived;
