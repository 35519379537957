import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Alert, Container, Row, Col } from "react-bootstrap";
import UploadFile from "./UploadFile";
import axios from "axios";
import { useForm } from "react-hook-form";
import "./form.scss";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Form = ({ setOrderSuccess }) => {
  const [devices, setDevices] = useState([]);
  const [purchaseImage, setPurchaseImage] = useState("");
  const [frontPurchaseImage, setFrontPurchaseImage] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const getDevicesData = async () => {
    const { data } = await axios.get(
      "https://xwguarantee.ejjadh.com/api/devices"
    );
    setDevices(data?.data?.devices);
  };

  useEffect(() => {
    getDevicesData();
    register("device", { required: true });
  }, [register]);

  const handleSelectOptions = devices?.map((device) => ({
    value: device?.id,
    label: device?.name,
  }));

  const handleSelectModelChange = (data) => {
    setSelectedOption(data);
    return setValue("device", data.value);
  };

  const onSubmit = async (data) => {
    if (!purchaseImage || !frontPurchaseImage) {
      return toast.error("لم يتم اختيار ملف", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    data.purchaseImage = `uploads/${purchaseImage?.filename}`;
    data.frontPurchaseImage = `uploads/${frontPurchaseImage?.filename}`;

    try {
      data.shopName = data?.city || " ";
      const response = await axios.post(
        "https://xwguarantee.ejjadh.com/api/invoices",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const invoiceId = response?.data?.data?.invoiceId;
      // message after sending the data {sucess message}
      setOrderSuccess(invoiceId);
      navigate("/success", { replace: true });

      // data reset after sending the data
      reset();
      setFrontPurchaseImage("");
      setPurchaseImage("");
      setSelectedOption("");
      setValue("device", "");
    } catch (error) {
      console.log(error);

      toast.error("يوجد خطأ فى ارسال البيانات ", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formWrapper">
          <Container>
            <div className="form_content__">
              <div className="form_group__">
                <h3 class="form_group_head__">البيانات</h3>
                <Row>
                  <Col lg="6" md="12" xs="12">
                    <div className="input_group__">
                      <span className="label req">الإسم</span>
                      <input
                        className={`input__ transition__ ${
                          errors?.userName ? "error__" : ""
                        }`}
                        type="text"
                        placeholder="الإسم"
                        {...register("userName", { required: true })}
                      />

                      {errors.userName && (
                        <Alert key="danger" variant="danger">
                          برجاء ادخال الإسم
                        </Alert>
                      )}
                    </div>
                  </Col>

                  <Col lg="6" md="12" xs="12">
                    <div className="input_group__">
                      <span className="label req">البريد الإلكتروني</span>
                      <input
                        className={`input__ transition__ ${
                          errors?.email ? "error__" : ""
                        }`}
                        type="text"
                        placeholder="البريد الإلكتروني"
                        {...register("email", { required: true })}
                      />

                      {errors.email && (
                        <Alert key="danger" variant="danger">
                          برجاء ادخال البريد الإلكتروني
                        </Alert>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" md="12" xs="12">
                    <div className="input_group__">
                      <span className="label req">رقم الهاتف</span>
                      <input
                        className={`input__ transition__ ${
                          errors?.phone ? "error__" : ""
                        }`}
                        type="text"
                        placeholder="رقم الهاتف"
                        {...register("phone", { required: true })}
                      />

                      {errors.phone && (
                        <Alert key="danger" variant="danger">
                          برجاء ادخال رقم الهاتف
                        </Alert>
                      )}
                    </div>
                  </Col>

                  <Col lg="6" md="12" xs="12">
                    <div className="input_group__">
                      <span className="label req">المدينة</span>
                      <input
                        className={`input__ transition__ ${
                          errors?.city ? "error__" : ""
                        }`}
                        type="text"
                        placeholder="المدينة"
                        {...register("city", { required: true })}
                      />

                      {errors.city && (
                        <Alert key="danger" variant="danger">
                          برجاء ادخال اسم المدينة
                        </Alert>
                      )}
                    </div>
                  </Col>

                  <Col lg="6" md="12" xs="12">
                    <div className="input_group__">
                      <span className="label req">موديل الجهاز</span>
                      <Select
                        className={`select_options__ ${
                          errors?.device ? "error__" : ""
                        } ${devices.length ? "" : "no_data_yet__"}`}
                        options={handleSelectOptions}
                        placeholder="اختر"
                        value={selectedOption}
                        classNamePrefix="select"
                        onChange={handleSelectModelChange}
                      />

                      {errors.device && (
                        <Alert key="danger" variant="danger">
                          برجاء اختيار موديل الجهاز
                        </Alert>
                      )}
                    </div>
                  </Col>

                  <Col lg="6" md="12" xs="12">
                    <div className="input_group__">
                      <span className="label req">
                        الرقم التسلسلي للجهاز (IMEE)
                      </span>
                      <input
                        className={`input__ transition__ ${
                          errors?.imee ? "error__" : ""
                        }`}
                        type="text"
                        placeholder="الرقم التسلسلي للجهاز (IMEE)"
                        {...register("imee", {
                          required: "برجاء ادخال الرقم التسلسلي للجهاز",
                          minLength: {
                            value: 10,
                            message: "برجاء اختيار كتابة رقم تسلسلى صحيح",
                          },
                          maxLength: {
                            value: 40,
                            message: "برجاء اختيار كتابة رقم تسلسلى صحيح",
                          },
                        })}
                      />

                      {errors.imee && (
                        <Alert key="danger" variant="danger">
                          {errors?.imee?.message}
                        </Alert>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <div className="form_group__ ">
                    <h3 class="form_group_head__">صــور الـشـراء</h3>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <UploadFile
                          title="أرفق صورة الشراء"
                          uploadedFile={purchaseImage}
                          setUploadedFile={setPurchaseImage}
                        />
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <UploadFile
                          title="أرفق صورة الشراء الأماماية عليها ستيكر الشركة"
                          uploadedFile={frontPurchaseImage}
                          setUploadedFile={setFrontPurchaseImage}
                        />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="6" md="12" xs="12">
                  <input
                    className="submit__ transition__"
                    type="submit"
                    value="إرسال"
                  />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Form;
